<template>
  <div v-if="brands.length" class="block">
    <div class="block__title mb-4">Бренды</div>
    <div class="brands">
      <div v-for="brand in brands" :key="brand.name" class="brand__wrapper mb-2">
        <div class="brand" :style="{ backgroundImage: `url(${brand.img})` }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/services/api'

export default {
  name: 'Brands',
  computed: {
    brands() {
      return this.$store.state.brands
    },
  },

  async mounted() {
    if (!this.brands.length) {
      const { brands } = await this.wrapAndHandleRequest(Api.getBrandList, false)
      this.$store.commit('SET_BRANDS', brands)
    }
  },
}
</script>

<style lang="scss">
.brands {
  display: flex;
  flex-wrap: wrap;
}

.brand {
  height: 100%;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;

  &__wrapper {
    width: 20%;
    padding: 10px;
    height: 150px;
    border-radius: 10px;
  }
}

@media (max-width: 600.98px) {
  .brand {
    &__wrapper {
      width: 50%;
    }
  }
}
</style>
