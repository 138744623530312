<template>
  <section class="main">
    <TopSlides class="mb-3" />
    <TopBanners class="mb-5" />
    <SaleHits class="mb-4" />
    <SecondTopBanners class="mb-4" />
    <SecondZoneSlides class="mb-5" />
    <Novelties />
    <Brands />
  </section>
</template>

<script>
import SaleHits from '@/views/main/components/SaleHits'
import TopSlides from '@/views/main/components/TopSlides'
import Novelties from '@/views/main/components/Novelties'
import TopBanners from '@/views/main/components/TopBanners'
import SecondTopBanners from '@/views/main/components/SecondTopBanners'
import Brands from '@/views/main/components/Brands'

export default {
  name: 'MainPage',
  components: {
    SaleHits,
    TopSlides,
    Novelties,
    TopBanners,
    SecondTopBanners,
    Brands,
    SecondZoneSlides: () => import('@/views/main/components/SecondZoneSlides'),
  },

  mounted() {

  },
}
</script>
