<template>
  <div v-if="novelties.length" class="sale-hits">
    <div class="block">
      <div class="block__title mb-4">Новинки</div>
      <div class="block__body">
        <VueSlickCarousel v-bind="settings" ref="noveltiesCarousel">
          <template v-if="!novelties || !novelties.length">
            <ProductCard v-for="n in 8" :key="n" />
          </template>
          <template v-else>
            <ProductCard v-for="product in novelties" :key="product.id" :product="product" />
          </template>
        </VueSlickCarousel>
        <span @click="moveCarousel('prev')" class="block__prev"><ArrowIcon direction="left" /></span>
        <span @click="moveCarousel('next')" class="block__next"><ArrowIcon direction="right" /></span>
      </div>
    </div>

    <div class="block">
      <div class="block__body">
        <VueSlickCarousel v-bind="settings" ref="noveltiesSecondCarousel">
          <template v-if="!noveltiesSecondLine || !noveltiesSecondLine.length">
            <ProductCard v-for="n in 8" :key="n" />
          </template>
          <template v-else>
            <ProductCard v-for="product in noveltiesSecondLine" :key="product.id" :product="product" />
          </template>
        </VueSlickCarousel>
        <span @click="moveSecondCarousel('prev')" class="block__prev"><ArrowIcon direction="left" /></span>
        <span @click="moveSecondCarousel('next')" class="block__next"><ArrowIcon direction="right" /></span>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import ProductCard from '@/components/Product/ProductCard'
import ArrowIcon from '@/components/ArrowIcon'
import Api from '@/services/api'

export default {
  name: 'Novelties',
  components: {
    VueSlickCarousel,
    ArrowIcon,
    ProductCard,
  },

  data: () => ({
    settings: {
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      swipe: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    },
  }),

  computed: {
    novelties() {
      const novelties = this.$store.state.novelties
      if (!novelties.length) return []
      return novelties[0]
    },

    noveltiesSecondLine() {
      const novelties = this.$store.state.novelties
      if (!novelties.length) return []
      return novelties[1]
    },
  },

  async mounted() {
    if (this.novelties.length || this.noveltiesSecondLine.length) return
    const { new_products } = await this.wrapAndHandleRequest(Api.getNovelties, false)
    this.$store.commit('SET_NOVELTIES', new_products)
  },

  methods: {
    moveCarousel(direction) {
      direction === 'next' ? this.$refs.noveltiesCarousel.next() : this.$refs.noveltiesCarousel.prev()
    },
    moveSecondCarousel(direction) {
      direction === 'next' ? this.$refs.noveltiesSecondCarousel.next() : this.$refs.noveltiesSecondCarousel.prev()
    },
  },
}
</script>
